<template>
  <el-card class="box-card">
    <el-form ref="form" label-width="110px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="活动名称" prop="name">
        <el-input v-model="form.name" size="small" style="width: 35%" placeholder="请输入活动名称"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动时间" prop="">
        <el-date-picker
            v-model="query_time"
            size="small"
            type="datetimerange"
            @change="getActivityTime"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            align="right"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动类型" prop="type">
        <el-radio-group v-model="form.type" size="small">
          <el-radio-button :label="0">积分返现</el-radio-button>
          <el-radio-button :label="1">消费次数返现</el-radio-button>
          <el-radio-button :label="2">累计消费金额达标</el-radio-button>
          <el-radio-button :label="3">第三方任务达标返现to do...</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="form.type == 3" style="margin-bottom: 20px" label="登陆接口" prop="">
        <el-input v-model="login_port" size="small" :disabled="true" style="width: 35%" placeholder="请输入接口链接"/>
      </el-form-item>
      <el-form-item v-show="form.type == 3" style="margin-bottom: 20px" label="查询接口" prop="">
        <el-input v-model="query_port" size="small" :disabled="true" style="width: 35%" placeholder="请输入接口链接"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="达标时间周期" prop="reach_time_period_type">
        <el-radio-group v-model="form.reach_time_period_type" size="small" @change="selectionStatus">
          <el-radio-button :label="0">统一有效时间</el-radio-button>
          <el-radio-button :label="1">参加后指定天数</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="unified" style="margin-bottom: 20px" label="统一有效时间" prop="">
        <el-date-picker
            v-model="unified_time"
            size="small"
            type="datetimerange"
            @change="getUnifiedTime"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            align="right"
        />
      </el-form-item>
      <el-form-item v-show="appoint" style="margin-bottom: 20px" label="参加后指定天数" prop="">
        <el-input v-model="form.days" size="small" style="width: 35%">
          <template slot="append">天</template>
        </el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="返现方式" prop="cashback_way">
        <el-radio-group v-model="form.cashback_way" size="small">
          <el-radio-button :label="0">用户余额</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动商品" prop="">
        <el-table v-loading="listLoading" :data="s_list" size="small" style="width: 70%"
                  @selection-change="setSelectRows">
          <el-table-column align="center" show-overflow-tooltip type="selection"/>
          <el-table-column
              align="center"
              label="商品图片"
              prop="imgUrl"
          >
            <template #default="{row}">
              <el-image v-if="row.imgUrl" :src="row.imgUrl" class="commodity_pic"/>
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="商品名称"
              prop="name"
          />
          <el-table-column
              align="center"
              label="库存"
              prop="stock"
          />
          <el-table-column
              align="center"
              label="商品价格"
              prop="good_price"
          />
          <el-table-column
              align="center"
              label="活动价格(元)"
              prop="price"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.price" v-model="s_list[scope.$index].price"
                        placeholder="请输入内容" :value="scope.row.price"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].price" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              v-if="form.type === 0"
              align="center"
              label="达标积分数"
              prop="reach_condition"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.reach_condition" v-model="s_list[scope.$index].reach_condition"
                        placeholder="请输入内容" :value="scope.row.reach_condition"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].reach_condition" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              v-if="form.type == 1"
              align="center"
              label="达标消费次数"
              prop="reach_condition"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.reach_condition" v-model="s_list[scope.$index].reach_condition"
                        placeholder="请输入内容" :value="scope.row.reach_condition"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].reach_condition" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              v-if="form.type == 2"
              align="center"
              label="达标消费金额"
              prop="reach_condition"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.reach_condition" v-model="s_list[scope.$index].reach_condition"
                        placeholder="请输入内容" :value="scope.row.reach_condition"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].reach_condition" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="返现金额%"
              prop="cashback_ratio"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.cashback_ratio" v-model="s_list[scope.$index].cashback_ratio"
                        placeholder="请输入内容" :value="scope.row.cashback_ratio"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].cashback_ratio" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button icon="el-icon-plus" type="primary" @click="showPop = true" size="small" style="margin-top: 10px">
          选择商品
        </el-button>
        <el-button v-if="s_list.length > 0" icon="el-icon-delete" size="small" type="danger" style="margin-top: 10px"
                   @click="handleDelete">批量删除
        </el-button>
        <el-button v-else icon="el-icon-delete" type="danger" size="small" style="margin-top: 10px" disabled>批量删除
        </el-button>
        <PopView :visible.sync="showPop" :chooseGoods="chooseGoods" title="选择商品" @confirm="showPopSubmit"></PopView>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>

import PopView from "@/views/components/popView";
import {apiGetPaymentMessage, apiOperationPayment} from "@/request/api";
import {formatDate} from "@/utils/theTimeStamp";

export default {
  components: {PopView},
  props: {
    chooseGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      login_port: '',
      query_port: '',
      listLoading: false,
      list: [],
      s_list: [],
      showPop: false,
      query_time: [],
      unified_time: [],
      unified: true,
      appoint: false,
      form: {
        id: '',
        name: '',
        begin_time: '',
        end_time: '',
        type: 0,
        cashback_way: 0,
        reach_time_period_type: 0,
        reach_begin_time: '',
        reach_end_time: '',
        days: '',
        goods: [],
      },
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '活动名不能为空'},
        ],
        type: [
          {required: true, trigger: 'blur', message: '活动类型不能为空'},
        ],
        reach_time_period_type: [
          {required: true, trigger: 'blur', message: '达标周期不能为空'},
        ],
        cashback_way: [
          {required: true, trigger: 'blur', message: '返现方式不能为空'},
        ],
      },
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetails()
    }
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    getDetails() {
      apiGetPaymentMessage({id: this.$route.query.id}).then(res => {
        if (res.code == 200) {
          console.log(res.data)
          const tempObj = res.data.cashback
          this.form = {
            id: tempObj.id,
            name: tempObj.name,
            begin_time: this.formatDate(tempObj.begin_time),
            end_time: this.formatDate(tempObj.end_time),
            type: tempObj.type,
            cashback_way: tempObj.cashback_way,
            reach_time_period_type: tempObj.reach_time_period_type,
            reach_begin_time: this.formatDate(tempObj.reach_begin_time),
            reach_end_time: this.formatDate(tempObj.reach_end_time),
            days: tempObj.days,
            goods: this.getGoods(res.data.cashbackGoods),
          }
          this.query_time = [this.formatDate(tempObj.begin_time), this.formatDate(tempObj.end_time)]
          if (res.data.cashbackGoods.reach_time_period_type != 1) {
            this.unified_time = [this.formatDate(tempObj.reach_begin_time), this.formatDate(tempObj.reach_end_time)]
          } else {
            this.unified_time = []
          }
        }
      })
    },
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
    getGoods(arr) {
      this.list = []
      if (arr.length > 0) {
        arr.forEach(item => {
          this.list.push({
            sku_id: item.sku_id,
            price: item.price,
            reach_condition: item.reach_condition,
            cashback_ratio: item.cashback_ratio,
          })
          this.s_list.push({
            sku_id: item.skuid,
            imgUrl: item.sku_info.imgUrl,//商品图片
            name: item.sku_info.name,//商品名
            good_price: item.sku_info.price,//价格
            stock: item.sku_info.stock,//库存
            price: item.price,
            reach_condition: item.reach_condition,
            cashback_ratio: item.cashback_ratio,
          })
        })
        return this.list
      }
    },
    //选择商品
    showPopSubmit(e) {
      this.showPop = true;
      console.log(e)
      if (e) {
        e.forEach(item => {
          this.s_list.push({
            sku_id: item.skuid,
            imgUrl: item.imgUrl,//商品图片
            name: item.name,//商品名
            good_price: item.price,//价格
            stock: item.stock,//库存
            price: item.price,//活动价
            reach_condition: 1,
            cashback_ratio: 1,//返现金额比例
          })
        })
        const smap = new Map()
        // 对id属性进行去重
        this.s_list = this.s_list.filter(key => !smap.has(key.sku_id) && smap.set(key.sku_id, 1))
        this.listLoading = false
      }
    },
    //删除选中商品
    handleDelete(row) {
      if (row.sku_id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.s_list.map((val, i) => {
            if (val.sku_id == row.sku_id) {
              this.s_list.splice(i, 1)
            }
          })
        })
      } else {
        this.$confirm('你确定要删除所有选中项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (var j = 0; j < this.selectRows.length; j++) {
            this.s_list.map((val, i) => {
              if (val.sku_id == this.selectRows[j].sku_id) {
                this.s_list.splice(i, 1)
              }
            })
          }
        })
      }
    },
    // 选择达标时间周期
    selectionStatus(event) {
      console.log(event)
      if (event == '1') {
        this.unified = false
        this.appoint = true
        this.unified_time = []
      } else {
        this.unified = true
        this.appoint = false
        this.days = ''
      }
    },
    // 获取活动时间
    getActivityTime(event) {
      console.log(event)
      if (event != null) {
        this.form.begin_time = event[0]
        this.form.end_time = event[1]
      } else {
        this.form.begin_time = ''
        this.form.end_time = ''
      }
    },
    // 获取统一有效时间
    getUnifiedTime(event) {
      console.log(event)
      if (event != null) {
        this.form.reach_begin_time = event[0]
        this.form.reach_end_time = event[1]
      } else {
        this.form.reach_begin_time = ''
        this.form.reach_end_time = ''
      }
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.query_time = []
      this.unified_time = []
      this.s_list = []
    },
    submit() {
      this.list = []
      if (this.s_list.length > 0) {
        this.s_list.forEach(item => {
          this.list.push({
            sku_id: item.sku_id,
            price: item.price,
            reach_condition: item.reach_condition,
            cashback_ratio: item.cashback_ratio,
          })
        })
      } else {
        this.$message.error('活动商品不能为空')
      }
      const map = new Map()
      // 对id属性进行去重
      this.form.goods = this.list.filter(key => !map.has(key.sku_id) && map.set(key.sku_id, 1))
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiOperationPayment(this.form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.close()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    },
  }
}

</script>
